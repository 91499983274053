import * as React from "react"
import {
  IconLwbFosterCare,
  IconAdoption,
  IconGuardianship,
  IconHealth,
  IconSupport,
  IconAt,
  IconBaby,
  IconCake,
  IconCaringStages,
  IconChat,
  IconClipboard,
  IconClock,
  IconCross,
  IconEducation,
  IconEmergency,
  IconExternal,
  IconFamily,
  IconGenericCategory,
  IconHeart,
  IconKinship,
  IconLegal,
  IconLongTerm,
  IconPhone,
  IconRespite,
  IconShortTerm,
  IconSpecialNeeds
} from "@life-without-barriers/react-components"

export const iconSelector = (type: string, color: string, height: string) => {
  if (type === "adoption") {
    return <IconAdoption color={color} height={height} />
  }
  if (type === "at") {
    return <IconAt color={color} height={height} />
  }
  if (type === "baby") {
    return <IconBaby color={color} height={height} />
  }
  if (type === "cake") {
    return <IconCake color={color} height={height} />
  }
  if (type === "caringstages") {
    return <IconCaringStages color={color} height={height} />
  }
  if (type === "chat") {
    return <IconChat color={color} height={height} />
  }
  if (type === "clipboard") {
    return <IconClipboard color={color} height={height} />
  }
  if (type === "clock") {
    return <IconClock color={color} height={height} />
  }
  if (type === "cross") {
    return <IconCross color={color} height={height} />
  }
  if (type === "education") {
    return <IconEducation color={color} height={height} />
  }
  if (type === "emergency") {
    return <IconEmergency color={color} height={height} />
  }
  if (type === "external") {
    return <IconExternal color={color} height={height} />
  }
  if (type === "family") {
    return <IconFamily color={color} height={height} />
  }
  if (type === "genericcategory") {
    return <IconGenericCategory color={color} height={height} />
  }
  if (type === "guardianship") {
    return <IconGuardianship color={color} height={height} />
  }
  if (type === "health") {
    return <IconHealth color={color} height={height} />
  }
  if (type === "heart") {
    return <IconHeart color={color} height={height} />
  }
  if (type === "kinship") {
    return <IconKinship color={color} height={height} />
  }
  if (type === "legal") {
    return <IconLegal color={color} height={height} />
  }
  if (type === "longterm") {
    return <IconLongTerm color={color} height={height} />
  }
  if (type === "phone") {
    return <IconPhone color={color} height={height} />
  }
  if (type === "respite") {
    return <IconRespite color={color} height={height} />
  }
  if (type === "shortterm") {
    return <IconShortTerm color={color} height={height} />
  }
  if (type === "specialneeds") {
    return <IconSpecialNeeds color={color} height={height} />
  }
  if (type === "support") {
    return <IconSupport color={color} height={height} />
  }
  return <IconLwbFosterCare color={color} height={height} />
}
