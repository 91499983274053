import * as React from "react"
import styled from "styled-components"
import {
  IconArrowRight,
  Link,
  PreStyledComponents,
  Variables
} from "@life-without-barriers/react-components"

const { IconWrapper } = PreStyledComponents
const { youthPinkMedium, youthPinkXXXLight } = Variables

export interface Group {
  id: string
  groupTitle: string
  icon: string | undefined
  categoryLinks: [
    {
      id: string
      categoryTitle: string
      slug: string
    }
  ]
}

interface GroupProps {
  group: Group
  selectIconFn: (
    type: string,
    color: string,
    height: string
  ) => React.ReactElement
}

const Title = styled.div`
  font-size: 12px;
  letter-spacing: 0.1rem;
`

const CategoryBordersAndPinkHover = styled(Link)`
  border-bottom-style: solid;
  border-bottom-width: 1px;

  &:hover {
    color: ${youthPinkMedium};
    background-color: ${youthPinkXXXLight};
  }

  @media screen and (min-width: 30em) {
    border-bottom-style: none;
    border-bottom-width: 0;
    border-top-style: solid;
    border-top-width: 1px;
  }

  &:last-child {
    border-bottom: none;
  }
`

const GroupTile = ({ group, selectIconFn }: GroupProps) => {
  const icon = group.icon ? group.icon.toLowerCase() : "lwbfostercare"
  return (
    <div className="br2-ns bg-lwb-white w-100 w-40-l mb3 mb4-ns mh3-ns flex-ns pv3 pa3-ns">
      <div className="dn db-ns pv3 ph2 w-10-m w-20-l">
        <IconWrapper className="center" size="50" color={youthPinkXXXLight}>
          {selectIconFn(icon, youthPinkMedium, "25")}
        </IconWrapper>
      </div>
      <div className="w-90-ns">
        <Title className="w-100 color-lwb-theme-dark fw4 ttu pv2 pt3-ns pb4-ns pl4">
          <div className="flex flex-justify">
            <div className="w-20 db dn-ns">
              <IconWrapper
                className="center"
                size="50"
                color={youthPinkXXXLight}
              >
                {selectIconFn(icon, youthPinkMedium, "25")}
              </IconWrapper>
            </div>
            <div className="w-80 pt2 pt3-ns">{group.groupTitle}</div>
          </div>
        </Title>

        <div className="flex flex-wrap">
          {group.categoryLinks &&
            group.categoryLinks.map((categoryLink, i) => (
              <CategoryBordersAndPinkHover
                key={i}
                className="br0 w-100 pv3 b-lwb-grey-x-light link color-lwb-black fw5 pl4"
                to={`/foster-care/support/carer-guide/category/${categoryLink.slug}`}
              >
                <div className="flex flex-justify-between">
                  <div className="w-90 f4 t-category-link-title">
                    {categoryLink.categoryTitle}
                  </div>
                  <div className="w-10">
                    <IconArrowRight
                      height="15"
                      width="6"
                      color={youthPinkMedium}
                      className="ml2"
                    />
                  </div>
                </div>
              </CategoryBordersAndPinkHover>
            ))}
        </div>
      </div>
    </div>
  )
}

export default GroupTile
