import * as React from "react"
import { navigate, graphql } from "gatsby"
import styled from "styled-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  PreStyledComponents,
  BannerFosterCare,
  Helmet,
  IconArrowLeft,
  Variables
} from "@life-without-barriers/react-components"
import { sendEvent } from "@life-without-barriers/utilities"
import { Site } from "@life-without-barriers/gatsby-common"

import { PinkHoverLink } from "../../../../components/carer-guide/localStyledComponents"
import GroupTile from "../../../../components/carer-guide/GroupTile"
import SearchBox from "../../../../components/carer-guide/SearchBox"
import Layout from "../../../../components/carer-guide/Layout"
import { toSearchQuery } from "../../../../lib/searchHelpers"
import { iconSelector } from "../../../../components/carer-guide/iconSelector"
import { Group } from "../../../../components/carer-guide/GroupTile"

const { Container, BackgroundHexGrey } = PreStyledComponents
const { youthPinkXLight } = Variables

interface IndexPageProps {
  location: { pathname: string }
  data: {
    bannerImage: IGatsbyImageData
    contentfulCarerGuideGroupParent: {
      groupLinks: [Group]
    }
    site: Site
  }
}

const HoverPinkSVG = styled.div`
  &:hover {
    path {
      fill: ${youthPinkXLight};
      transition: fill 200ms ease-in;
    }
  }
`

const BackToFosterCare = ({ siteUrl }: { siteUrl: string }) => (
  <HoverPinkSVG className="ttu fw6">
    <PinkHoverLink to={`${siteUrl}/foster-care`} className="link white fw4">
      <IconArrowLeft className="link" height="10" width="6" />
      <span className="pl3 b">Back to foster care site</span>
    </PinkHoverLink>
  </HoverPinkSVG>
)

const onSearch = (queryValue: string) => {
  sendEvent("Home page", "Click", "Search button")
  void navigate(
    `/foster-care/support/carer-guide/search/?${toSearchQuery(queryValue)}`
  )
}

const IndexPage = ({
  location,
  data: {
    bannerImage,
    contentfulCarerGuideGroupParent: { groupLinks },
    site: { siteMetadata }
  }
}: IndexPageProps) => {
  return (
    <Layout location={location} siteMetadata={siteMetadata}>
      <BackgroundHexGrey>
        <Helmet
          title={`Carer guide | ${siteMetadata.title}`}
          description="LWB - Carer guide"
          siteUrl={siteMetadata.siteUrl}
          path={location.pathname}
          image={bannerImage}
        />

        <BannerFosterCare
          image={bannerImage}
          title="Carer Guide"
          verticalPadding="pv4 pb5-ns"
          alt="Female foster carer smiling and holding her toddler grand-son"
        >
          <div className="color-lwb-white tc pv3-ns">
            <div className="tl dn db-ns mt3-ns">
              <BackToFosterCare siteUrl={siteMetadata.siteUrl} />
            </div>
            <h1 className="ts-display-1 ph0 ph3-ns ma0 mv4 mt5-ns center fw8">
              Carer Guide
            </h1>
            <div className="mv4 mb0-ns">
              <SearchBox
                id="home"
                onSearch={onSearch}
                className="w-100 w-70-l center"
              />
            </div>
            <div className="db dn-ns">
              <BackToFosterCare siteUrl={siteMetadata.siteUrl} />
            </div>
          </div>
        </BannerFosterCare>
        <div className="ph0 ph4-ns pv0 pv5-ns">
          <Container>
            <h2
              className="ts-display-3 fw7 tc color-lwb-theme-medium pb4-m pb5-l dn db-ns"
              aria-level={2}
              role="heading"
            >
              Looking for something? We have the answers
            </h2>

            <div className="flex-ns flex-wrap justify-center-ns pb3 pb0-ns">
              {groupLinks.map((group, i) => (
                <GroupTile key={i} group={group} selectIconFn={iconSelector} />
              ))}
            </div>
          </Container>
        </div>
      </BackgroundHexGrey>
    </Layout>
  )
}

export const query = graphql`
  query GroupParentQuery {
    contentfulCarerGuideGroupParent {
      groupLinks {
        id
        groupTitle
        icon
        categoryLinks {
          id
          categoryTitle
          slug
        }
      }
    }
    bannerImage: file(relativePath: { regex: "/homepage-banner.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default IndexPage
